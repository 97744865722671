import React, { Component } from "react"
import { graphql } from "gatsby"
import ImageGallery from "react-image-gallery"
import $ from "jquery"

import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"

import { woodShopPageImages } from "../../constants/images.js"
import { WoodProduct } from "../../constants/products.js"
import { PostCodes } from "../../constants/postcodes"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { loadStripe } from "@stripe/stripe-js"
let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_test_51HXRaHDaY5da5KQsGDKiRd8iIo1iPKrLmlZ7LJwEXTjV0VjTXLjhUA0NnHb8Eql2o8zCSA4CM6Bq9egCJ3K54YRT00cOGHfS96"
    )
  }
  return stripePromise
}

class ProductGallery extends React.Component {
  render() {
    return (
      <ImageGallery
        showFullscreenButton={false}
        showPlayButton={false}
        items={woodShopPageImages.carousel.items}
      />
    )
  }
}

export default class strawShopPage extends Component {
                 componentDidMount() {
                   $(".btn-plus, .btn-minus").on("click", function(e) {
                     const isNegative = $(e.target)
                       .closest(".btn-minus")
                       .is(".btn-minus")
                     const input = $(e.target)
                       .closest(".input-group")
                       .find("input")
                     if (input.is("input")) {
                       input[0][isNegative ? "stepDown" : "stepUp"]()
                     }
                   })
                 }
                 constructor() {
                   super()
                   this.weight = React.createRef()
                   this.quantity = React.createRef()
                   this.state = {
                     product_price: "",
                     post_code: "",
                     active_zone: "",
                   }
                   this.handleChange = this.handleChange.bind(this)
                 }
                 handleChange(e) {
                   this.setState({
                     product_price: e.target,
                     product_vat:
                       e.target.selectedOptions[0].dataset.price * 0.2,
                   })
                 }

                 postCodeCheck(codeToCheck) {
                   let userPostCode = codeToCheck.replace(/ /g, "")
                   console.log(userPostCode)
                   this.setState({ post_code: userPostCode })
                   if (userPostCode.length > 1) {
                     let active_zone = ""
                     PostCodes.zones.forEach(function(zone, index) {
                       const codes = zone.post_codes.split(",")
                       codes.forEach(function(code, index) {
                         if (userPostCode.toUpperCase().includes(code)) {
                           active_zone = zone
                         }
                       })
                     })
                     if (active_zone !== "") {
                       this.setState({ active_zone: active_zone })
                     } else {
                       active_zone = ""
                     }
                   }
                 }

                 buyProduct = async e => {
                   e.preventDefault()
                   const form = e.target

                   const formData = {
                     "form-name": "productForm",
                     weight: this.weight.current.value,
                     quantity: this.quantity.current.value,
                   }

                   fetch(form.action, {
                     method: form.method,
                     body: new FormData(form),
                   })
                   const product = this.weight.current.value
                   const shipping = this.state.active_zone.stripe_price_id
                   const stripe = await getStripe()
                   const { error } = await stripe.redirectToCheckout({
                     mode: "payment",
                     shippingAddressCollection: {
                       allowedCountries: ["GB"],
                     },
                     lineItems: [
                       {
                         price: product,
                         quantity: 1,
                       },
                       {
                         price: shipping,
                         quantity: 1,
                       },
                     ],
                     successUrl: `${window.location.origin}/success`,
                     cancelUrl: `${window.location.origin}`,
                   })
                   if (error) {
                     console.warn("Error:", error)
                   }
                 }
                 render() {
                   const description = remark()
                     .use(recommended)
                     .use(remarkHtml)
                     .processSync(
                       this.props.data.markdownRemark.frontmatter.description
                     )
                     .toString()
                   return (
                     <Layout pageInfo={{ pageName: "Buy Straw Pellets" }}>
                       <SEO title="Buy Straw Pellets" />
                       <div>
                         <div className="fw banner-inner wood-banner-inner">
                           <div className="container">
                             <div className="row">
                               <div className="col-12 text-center">
                                 <h1>Wood Pellets</h1>
                               </div>
                             </div>
                           </div>
                         </div>
                         <div className="fw warm-grey-bg watermark-center sect-pad-top sect-pad-bot">
                           <div className="container">
                             <div className="row">
                               <div className="col-sm-12 col-lg-6">
                                 <div className="fw padd-right-wrap">
                                   <ProductGallery />
                                 </div>
                               </div>
                               <div className="col-sm-12 col-lg-6 large-p">
                                 <div className="fw padd-left-wrap">
                                   <h2 className="highlight">
                                     {
                                       this.props.data.markdownRemark
                                         .frontmatter.title
                                     }
                                   </h2>
                                   <div
                                     dangerouslySetInnerHTML={{
                                       __html: description,
                                     }}
												   />
									<h2 className="pt-4">Currently out of stock</h2>
                                   {/* <form
                                     id="WoodProduct"
                                     method="POST"
                                     action=""
                                     name="productForm"
                                     onSubmit={this.buyProduct}
                                     onKeyPress={e => {
                                       e.key === "Enter" && e.preventDefault()
                                     }}
                                   >
                                     <div className="select">
                                       <select
                                         name="weight"
                                         ref={this.weight}
                                         onChange={this.handleChange}
                                       >
                                         <option
                                           className="selectPlaceholder"
                                           value
                                           disabled
                                           selected
                                           data-price=""
                                         >
                                           Select weight...
                                         </option>
                                         {WoodProduct.variations.map(
                                           variation => (
                                             <option
                                               value={variation.stripe_price_id}
                                               data-price={variation.price}
                                             >
                                               {variation.text}
                                             </option>
                                           )
                                         )}
                                       </select>
                                     </div>
                                     <div className="product-price">
                                       <h2>
                                         {this.state.product_price !== ""
                                           ? `£${this.state.product_price
                                               .selectedOptions[0].dataset
                                               .price * this.state.quantity}`
                                           : ""}
                                       </h2>
                                       <h4>
                                         {this.state.product_price !== ""
                                           ? `+ £${
                                               this.state.product_vat.toFixed(
                                                 2
                                               ) > 0
                                                 ? this.state.product_vat.toFixed(
                                                     2
                                                   ) * this.state.quantity
                                                 : ""
                                             } VAT`
                                           : "Select Weight"}
                                       </h4>
                                     </div>
                                     <span>
                                       {this.state.active_zone !== ""
                                         ? `+ £${this.state.active_zone.cost} Shipping on ${this.state.active_zone.name}`
                                         : ""}
                                     </span>
                                     <div className="product-purchase">
                                       <div className="input-group inline-group">
                                         <div className="quantity-label">
                                           <h4>Quantity:</h4>
                                         </div>
                                         <div className="quantity-buttons">
                                           <div className="input-group-append">
                                             <button className="btn-plus">
                                               <p>+</p>
                                             </button>
                                           </div>
                                           <div className="input-group-prepend">
                                             <button className="btn-minus">
                                               <p>-</p>
                                             </button>
                                           </div>
                                         </div>
                                         <input
                                           className="form-control quantity"
                                           min={1}
                                           name="quantity"
                                           defaultValue={1}
                                           type="number"
                                           ref={this.quantity}
                                         />
                                         <input
                                           type="submit"
                                           id="submit"
                                           value="Add to cart"
                                           className="btn"
                                         />
                                       </div>
                                       <div className="input-group inline-group">
                                         <div className="quantity-label">
                                           <h4>Shipping postcode:</h4>
                                         </div>
                                         <input
                                           placeholder="Postcode"
                                           className="form-control postcode"
                                           type="text"
                                           name="postcode"
                                           autocomplete="off"
                                           onInput={e => {
                                             this.postCodeCheck(e.target.value)
                                           }}
                                         ></input>
                                       </div>
                                     </div>
                                   </form> */}
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </Layout>
                   )
                 }
               }

export const pageQuery = graphql`
  query WoodProductQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pwood.md/" }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
